.logo {
  height: 120px;
  width: 130px;
  margin-top: 10px;
}

.subheader {
  color: 'darkslategrey';
  width: '100%';
  font-size: 22px;
}

.ai-website-image {
  height: 200px;
  width: 200px;
  margin-top: 25px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.website-label {
  text-align: center;
  margin-top: 7px;
  font-size: 18px;
  text-decoration: none;
}

.ai-website-url {
  text-decoration: none;
  color: black;
}